import React from "react";
import Hero from "../components/Hero";

function Contact() {
  const contactHeroContent = (
    <h1 className="contact-hero-title">
      Ready to Accelerate Your Business?
      <br />
      <span className="contact-hero-subtitle">Connect with Us</span>
    </h1>
  );

  return (
    <>
      <Hero content={contactHeroContent} background="contact-hero" />
      <p className="pageContent">hello, contact</p>
    </>
  );
}

export default Contact;
